<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params)">
        <span>{{ organizationTypeString(params) }}</span>
    </vs-chip>
</template>

<script>
export default {
    name: 'CellRendererStatus',
    computed: {
        chipColor() {
            return value => {
                if (value.data.status === 0) {
                    return "warning"
                } else if (value.data.status === 1) {
                    return "success"
                } else if (value.data.status === 2) {
                    return "danger"
                } else if (value.data.status === 3) {
                    return "cancel"
                }
            }
        },
        organizationTypeString() {
            return value => {
                if (value.data.status === 0) {
                    return "Chờ"
                } else if (value.data.status === 2) {
                    return "Từ chối"
                } else if (value.data.status === 1) {
                    return "Đã duyệt"
                } else if (value.data.status == 3) {
                    return "Đã hủy"
                }
            }
        }
    }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
    &.vs-chip-success {
        background: rgba(var(--vs-success), .15);
        color: rgba(var(--vs-success), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-warning {
        background: rgba(var(--vs-warning), .15);
        color: rgba(var(--vs-warning), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-danger {
        background: rgba(var(--vs-danger), .15);
        color: rgba(var(--vs-danger), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-cancel {
        background: rgba(var(--vs-cancel), .15);
        color: rgba(var(--vs-cancel), 1) !important;
        font-weight: 500;
    }
}
</style>
