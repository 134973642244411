<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="flex-grow">
                <div class="vx-row">
                    <div style="display:inline-block" class="vx-col md:w-1/3 sm:w-auto">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                                  :options="organizationBranchOptions">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                    </div>
                    <div style="display:inline-block" class="vx-col md:w-1/3 sm:w-auto">
                        <label class="vs-input--label">Nhân viên</label>
                        <v-select class="" v-model="selectedEmployee" :clearable="false" :options="employeeOptions">
                            <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                    </div>
                </div>
                <div class="vx-row">
                    <div style="display:inline-block" class="vx-col md:w-1/3 sm:w-auto mt-2">
                        <label class="vs-input--label">Trạng thái</label>
                        <v-select class="" v-model="inOutStatusModel" :clearable="false" :options="leaveStatusOptions"/>
                    </div>
                    <div class="vx-col mt-2">
                        <label class="vs-input--label">Thời gian: </label>
                        <div class="flex-grow">
                            <date-range-picker
                                ref="picker"
                                :locale-data="{format: 'dd/mm/yyyy' }"
                                :opens="'left'"
                                @update="dateRangePicker"
                                v-model="dateRange">
                            </date-range-picker>
                        </div>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-26">
                        <div class="flex-grow">
                            <vs-button @click="loadInOut">Lọc</vs-button>
                            <vs-button class="ml-5" @click="addInOut">Tạo đơn</vs-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap items-center mt-5">
                <div class="flex-grow"></div>
                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="inOutData"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="inOutData"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :localeText=" {noRowsToShow: ' '}"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import Pagination from "../../ui-elements/Pagination.vue"
import flatPickr from 'vue-flatpickr-component';
import DateRangePicker from "../../../components/vue2-daterange-picker/components/DateRangePicker";
import 'flatpickr/dist/flatpickr.css';
import CellRendererStatus from "./cell-renderer/CellRendererStatus";

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererActions,
        CellRendererStatus,
        Datepicker,
        Pagination,
        DateRangePicker,
        flatPickr
    },
    data() {
        return {
            //isInitComplete: false,
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Mã NV',
                    field: 'employeeCode',
                    width: 120
                },
                {
                    headerName: 'Tên NV',
                    field: 'fullName',
                    width: 180
                },
                {
                    headerName: 'Ngày',
                    field: 'displayInOutDate',
                    width: 150,
                },
                {
                    headerName: 'Ca',
                    field: 'workShiftName',
                    width: 150,
                },
                {
                    headerName: 'Giờ vào ra',
                    field: 'displayInOutTime',
                    width: 150,
                },
                {
                    headerName: 'Trạng thái',
                    field: 'displayStatus',
                    width: 100,
                    cellRendererFramework: 'CellRendererStatus'
                },
                {
                    headerName: 'Lý do',
                    field: 'reason',
                    width: 165,
                },
                {
                    headerName: 'Hành động',
                    field: 'actions',
                    width: 200,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererStatus
            },
            inOutData: [],
            employeeOptions: [
                {value: null, label: 'Tất cả'}
            ],
            leaveStatusOptions: [
                {value: null, label: 'Tất cả'},
                {value: 0, label: 'Chờ'},
                {value: 1, label: 'Đã duyệt'},
                {value: 2, label: 'Từ chối'},
                {value: 3, label: 'Hủy bỏ'}
            ],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
            selectedEmployeeValue: null,
            selectedStatus: null,
            status: null,
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setHours(0, 0, 0);
                    return date
                })()
            },
        }
    },
    created() {
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateInOut'(val) {
            if (val) {
                this.$store.commit('UPDATE_IN_OUT', false);
                this.initScreen();
                this.loadInOut();
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value;
                this.selectedEmployeeValue = null;
                this.loadEmployee()
            }
        },
        selectedEmployee: {
            get() {
                return {
                    label: this.employeeString(this.selectedEmployeeValue),
                    value: this.selectedEmployeeValue
                }
            },
            set(obj) {
                this.selectedEmployeeValue = obj.value
            }
        },
        inOutStatusModel: {
            get() {
                return {
                    label: this.leaveStatusOptions.find(x => x.value == this.status).label,
                    value: this.status
                }
            },
            set(obj) {
                this.status = obj.value;
            }
        },
    },
    methods: {
        dateRangePicker: function () {
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
        },
        addInOut() {
            this.$router.push(`/user/update-in-out-by-id?branch_id=${this.selectedOrganizationBranchValue}`).catch(() => {
            });
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value == id);
            return (organizationBranch && organizationBranch.label) || 'Chưa xác định';
        },
        employeeString(id) {
            let employee = this.employeeOptions.find(x => x.value == id);
            return (employee && employee.label) || 'Chưa xác định';
        },
        loadEmployee() {
            if (!this.selectedOrganizationBranchValue) return;

            this.$vs.loading();
            this.$crm.post('/employee/find-by-organization-branch/' + this.selectedOrganizationBranchValue).then((response) => {
                this.employeeData = response.data;
                this.employeeOptions = response.data.map(item => {
                    return {
                        value: item.employeeId,
                        label: item.employeeCode + " " + item.fullName,
                        ...item
                    }
                });
                this.employeeOptions.unshift({value: null, label: 'Tất cả'})
                this.$vs.loading.close();
                this.loadInOut();
            }).catch((err) => {
                this.$vs.loading.close();
                //this.isInitComplete = true;
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            if (branchList.length === 0) {
                return this.$vs.notify({
                    text: 'Hiện tại không tồn tại chi nhánh nào',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];

        },
        loadInOut() {
            if (!this.selectedOrganizationBranchValue) return;
            let condition = {
                organizationBranchId: this.selectedOrganizationBranchValue,
                employeeId: this.selectedEmployeeValue,
                status: this.status,
                fromDate: moment(this.dateRange.startDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}),
                toDate: moment(this.dateRange.endDate).set({hour: 23, minute: 59, second: 59, millisecond: 0})
            }
            this.$vs.loading();
            this.$crm.post('/work-shift/employee/admin-find-update-in-out', condition).then((response) => {
                this.inOutData = response.data.map(item => {
                    item.displayInOutDate = moment(item.date).format("DD/MM");
                    item.displayLeaveType = item.leaveType == 0 ? 'Nghỉ phép' : 'Nghỉ thường';
                    item.displayInOutTime = moment(item.startHour).format("HH:mm") + ' - ' + moment(item.endHour).format("HH:mm");
                    item.displayStatus = item.status == 0 ? 'Chờ' : item.status == 1 ? 'Đã duyệt' : item.status == 2 ? 'Từ chối' : 'Hủy';
                    let employee = this.employeeOptions.find(employee => employee.employeeId == item.employeeId);
                    item = {...item, ...employee}
                    return item;
                });
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>
.vue-daterange-picker .ranges {
    float: right;
    border-left: 1px solid #ddd;
}

.daterangepicker {
    border-left: none !important;
}

.title-page {
    font-size: 2.2rem;
    font-weight: bold;
    display: flex;
}

.text-font {
    opacity: 0.7;
}

.reportrange-text {
    border-radius: 5px;
    height: 38px;
    line-height: 27px;
}

.mt-26 {
    margin-top: 26px;
}
</style>
